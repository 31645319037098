import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import {
  AlexaButton,
  AlexaModal,
  AlexaModalProps,
  AlexaPermissionModal,
  AlexaPermissionModalProps,
  AlexaText,
  alexaTheme
} from '@amzn/monera-app-alexa-shared-libs';
import {
  coreTheme,
  isClientSide,
  serverConsole,
  useConfigStore,
  useShoppingPortalStore,
  withViewAnalytics
} from '@amzn/monera-app-core';
import { CanvasContainer, CanvasItem, List } from '@amzn/monera-app-core-components';
import { redirectToAuthPortalV2 } from '@amzn/monera-app-core/lib/utils/auth';
import { emitCountMetrics } from '@amzn/monera-app-core/lib/utils/metric-helper';

import {
  AlexaDisableSkillModal,
  AlexaDisableSkillModalProps,
  AlexaKSTModal,
  AlexaKSTModalProps,
  RestrictionMessage,
  SkillSpecificMessage
} from '../';
import { enableSkill, getAccountLinkingURI, getVPCWidgetData, rehydrateBuyBox } from '../../services';
import { AlertBannerState } from '../../state/stores/free-skill-alert-banner-group';
import { alertBannerActions } from '../../state/stores/free-skill-alert-banner-group/actions';
import { BuyBoxState, useBuyBoxStore } from '../../state/stores/free-skill-buy-box';
import { initialBuyBoxState } from '../../state/stores/free-skill-buy-box/state';
import { MShopDownloadAlexaApp } from '../mshop-download-alexa-app';
import { DrsMessageDiv, InfoIcon } from './styles';
import { ButtonActionTypes, BuyBoxProps } from './types';
import { useBuyBoxMetaStore } from "../../state/stores/free-skill-buy-box-meta";
import { initialBuyBoxMetaState } from "../../state/stores/free-skill-buy-box-meta/state";

const BuyBoxMetric = {
  methodName: 'BuyBoxProps',
  errorMetricName: 'BuyBoxProps:Error',
  successMetricName: 'BuyBoxProps:Success'
};

const AccountLinkingMetric = {
  methodName: 'AccountLinkingFlow',
  errorMetricName: 'AccountLinkingFlow:Error',
  successMetricName: 'AccountLinkingFlow:Success'
};

export const FreeSkillBuyBox: FunctionComponent<BuyBoxProps> = (props) => {
  const [configRegistryState] = useConfigStore();
  const [shoppingPortalRegistryState] = useShoppingPortalStore();
  const [buyBoxState, buyBoxActions] = useBuyBoxStore();
  const [buyBoxMetaState, buyBoxMetaActions] = useBuyBoxMetaStore();
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState<boolean>(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const [isKSTModalOpen, setIsKSTModalOpen] = useState<boolean>(false);
  const [isDiscoverDevicesModalOpen, setIsDiscoverDevicesModalOpen] = useState<boolean>(false);
  const [isDRSDiscoverDevicesModalOpen, setIsDRSDiscoverDevicesModalOpen] = useState<boolean>(false);
  const [isEnabling, setIsEnabling] = useState<boolean>(false);
  const [isDisabling, setIsDisabling] = useState<boolean>(false);
  const [isSavingPermissions, setIsSavingPermissions] = useState<boolean>(false);
  const wasDRSShown = useRef(false);
  const emitMetricsFromBuyBoxProps = () => {
    async function emitMetricsForBuyBox() {
      if (props == null || props?.error) {
        serverConsole.log(
          `Error while rendering BuyBox for customerId: ${shoppingPortalRegistryState?.customerId} and requestId: ${configRegistryState?.requestId} `
        );
        await emitCountMetrics(
          BuyBoxMetric.methodName,
          BuyBoxMetric.errorMetricName,
          1,
          configRegistryState.requestId,
          configRegistryState.tenant
        );
      } else {
        await emitCountMetrics(
          BuyBoxMetric.methodName,
          BuyBoxMetric.successMetricName,
          1,
          configRegistryState.requestId,
          configRegistryState.tenant
        );
      }
    }
    emitMetricsForBuyBox()
      .then(() => serverConsole.log('Successfully emitted BuyBox Metrics with props: ', props))
      .catch((err: any) => serverConsole.error(`Error while emitting BuyBox metrics: ${err}`));
  };
  useEffect(() => {
    if (buyBoxState === initialBuyBoxState) {
      buyBoxActions.update(props);
    }
    if (buyBoxMetaState === initialBuyBoxMetaState) {
      buyBoxMetaActions.update({
        accountLinkUri: props?.actionBoxData?.accountLinkURI ?? undefined,
      });
    }
  }, []);
  useEffect(() => {
    emitMetricsFromBuyBoxProps();
  }, [buyBoxState]);
  const {
    restrictionMessage,
    headingCopy,
    skillTitle,
    footerCopy,
    actionBoxData,
    permissionsInfoData,
    discoverDeviceModalData,
    disableModalData,
    isAuthenticatedUser,
    isSkillEnabled,
    deviceType,
    locale,
    defaultLocale,
    localizedProductDetails,
    mshopDownloadAlexaAppData,
    drsManageDeviceText
  } = buyBoxState;

  if (!disableModalData || !skillTitle || !actionBoxData) {
    serverConsole.log('Free skill buy box state does not contain all the data required to render properly');
    return <></>;
  }

  let restrictionOverride = false;
  if (isClientSide()) {
    restrictionOverride = window.location.href.includes(`overrideRestriction=true`);
  }

  const reloadOnAccountLinkWindowClose = (accountLinkURI: string, isEnable?: boolean) => {
    if (accountLinkURI !== undefined) {
      const accountLinkWindow = window.open(accountLinkURI, '_blank');
      const timer = setInterval(
        async () => {
          if (accountLinkWindow === null || accountLinkWindow.closed) {
            clearInterval(timer);
            const response = await rehydrateBuyBox({
              requestId: configRegistryState.requestId,
              asin: configRegistryState.asin,
              tenant: configRegistryState.tenant ?? '',
              marketplaceId: shoppingPortalRegistryState.marketplaceId,
              sessionId: shoppingPortalRegistryState.sessionId,
              ubid: shoppingPortalRegistryState.ubid,
              csrfToken: configRegistryState.csrfToken,
              customerId: shoppingPortalRegistryState.customerId,
              deviceType,
              locale,
              defaultLocale,
              localizedProductDetails
            });
            if (response) {
              buyBoxActions.update(response as BuyBoxState);
              if (isEnable && (response as BuyBoxState).permissionsInfoData) {
                window.dispatchEvent(new Event('showPermissionModal'));
                handlePermissionModalOpen();
              }
              if (response['alertMessages'] !== undefined) {
                const alertBannerState = { alertMessages: response['alertMessages'] };
                alertBannerActions.update(alertBannerState as AlertBannerState);
              }
              emitAccountLinkingMetrics(response);
            }
            setIsEnabling(false);
          }
        },
        500,
        accountLinkWindow
      );
    }
  };

  const drsOnShowEmbeddedBrowserSuccess = () => {
    document.addEventListener("visibilitychange", async () => {
      if (document.visibilityState === "visible") {
        // back to MSHOP app
        // @see https://w.amazon.com/bin/view/AppWebRendering/Projects/InApp3pBrowser/APIs#HHowtodetectCCT2FSVCmodaldismiss
        await drsOnDoneButtonTap();
      }
    });
  };

  const drsOnDoneButtonTap = async () => {
    const response = await enableSkill({
      requestId: configRegistryState.requestId,
      asin: configRegistryState.asin,
      tenant: configRegistryState.tenant ?? '',
      marketplaceId: shoppingPortalRegistryState.marketplaceId,
      sessionId: shoppingPortalRegistryState.sessionId,
      ubid: shoppingPortalRegistryState.ubid,
      csrfToken: configRegistryState.csrfToken,
      customerId: shoppingPortalRegistryState.customerId,
      isSmartHome: true,
      deviceType,
      locale,
      defaultLocale,
      localizedProductDetails
    });
    if ((response as BuyBoxState)?.isSkillEnabled) {
      buyBoxActions.update(response as BuyBoxState);
      handleDRSDiscoverDevicesModalOpen();
    }
  };

  const enableOnAccountLinkWindowClose = (accountLinkURI: string) => {
    if (accountLinkURI !== undefined) {
      if (deviceType === 'MSHOP') {
        setIsEnabling(false);
        //* openInApp3pBrowser
        //* @see https://w.amazon.com/bin/view/AppWebRendering/Projects/InApp3pBrowser/APIs
        (window as any).P.when('ThirdPartyNavigation').execute(function(thirdPartyNavigation: any){
          thirdPartyNavigation.openInApp3pBrowser( {
            targetURL: accountLinkURI,
            callerIdentifier : "MoneraAppAlexaFree-AccountLinking",
            navigationStartTime : Date.now(),
            successCallback: drsOnShowEmbeddedBrowserSuccess,
          });
        });
      } else {
        const accountLinkWindow = window.open(accountLinkURI, '_blank');
        const timer = setInterval(
          async () => {
            if (accountLinkWindow === null || accountLinkWindow.closed) {
              clearInterval(timer);
              const response = await enableSkill({
                requestId: configRegistryState.requestId,
                asin: configRegistryState.asin,
                tenant: configRegistryState.tenant ?? '',
                marketplaceId: shoppingPortalRegistryState.marketplaceId,
                sessionId: shoppingPortalRegistryState.sessionId,
                ubid: shoppingPortalRegistryState.ubid,
                csrfToken: configRegistryState.csrfToken,
                customerId: shoppingPortalRegistryState.customerId,
                isSmartHome: true,
                deviceType,
                locale,
                defaultLocale,
                localizedProductDetails
              });
              if ((response as BuyBoxState)?.actionBoxData?.isSmartHomeSkill) {
                buyBoxActions.update(response as BuyBoxState);
                handleDiscoverDevicesModalOpen();
                emitAccountLinkingMetrics(response);
              }
              setIsEnabling(false);
            }
          },
          100,
          accountLinkWindow
        );
      }
    }
  };

  const checkVPCTokenPresence = () => {
    if (props?.actionBoxData?.parentalConsentMessageData?.vpcRequired) {
      if (props?.actionBoxData?.parentalConsentMessageData?.vpcStatus) {
        window.sessionStorage.setItem('Initial_invoke', 'yes');
      }
      return props?.actionBoxData?.parentalConsentMessageData?.vpcStatus;
    } else {
      return false;
    }
  };

  const handleVPCToken = async () => {
    const isVPCTokenValid = checkVPCTokenPresence();
    if (isVPCTokenValid) {
      const response = await enableSkill({
        requestId: configRegistryState.requestId,
        asin: configRegistryState.asin,
        tenant: configRegistryState.tenant ?? '',
        marketplaceId: shoppingPortalRegistryState.marketplaceId,
        sessionId: shoppingPortalRegistryState.sessionId,
        ubid: shoppingPortalRegistryState.ubid,
        csrfToken: configRegistryState.csrfToken,
        customerId: shoppingPortalRegistryState.customerId,
        deviceType,
        locale,
        defaultLocale,
        localizedProductDetails
      });
      if (response) {
        buyBoxActions.update(response as BuyBoxState);
        if ((response as BuyBoxState).permissionsInfoData) {
          window.dispatchEvent(new Event('showPermissionModal'));
          handlePermissionModalOpen();
        }
      }
      setIsEnabling(false);
    }
    // reloadPage(true);
  };

  if (isClientSide()) {
    if (
      window.location.href.includes('VPCToken=') &&
      !isSkillEnabled &&
      window.sessionStorage.getItem('Initial_invoke') == null
    ) {
      handleVPCToken();
    }
  }

  const handlePermissionModalClose = () => {
    setIsPermissionModalOpen(false);
  };

  const handlePermissionModalOpen = async () => {
    const response = await rehydrateBuyBox({
      requestId: configRegistryState.requestId,
      asin: configRegistryState.asin,
      tenant: configRegistryState.tenant ?? '',
      marketplaceId: shoppingPortalRegistryState.marketplaceId,
      sessionId: shoppingPortalRegistryState.sessionId,
      ubid: shoppingPortalRegistryState.ubid,
      csrfToken: configRegistryState.csrfToken,
      customerId: shoppingPortalRegistryState.customerId,
      deviceType,
      locale,
      defaultLocale,
      localizedProductDetails
    });
    if (response) {
      buyBoxActions.update(response as BuyBoxState);
      if (response['alertMessages'] !== undefined) {
        const alertBannerState = { alertMessages: response['alertMessages'] };
        alertBannerActions.update(alertBannerState as AlertBannerState);
      }
    }
    setIsPermissionModalOpen(true);
  };

  const handleDisableModalClose = () => {
    setIsDisableModalOpen(false);
  };

  const handleDisableModalOpen = () => {
    if (wasDRSShown.current) {
      wasDRSShown.current = false;
    }
    setIsDisableModalOpen(true);
  };

  const handleKSTModalClose = () => {
    setIsKSTModalOpen(false);
  };

  const handleKSTModalOpen = () => {
    setIsKSTModalOpen(true);
  };

  const handleDiscoverDevicesModalClose = () => {
    setIsDiscoverDevicesModalOpen(false);
  };

  const handleDiscoverDevicesModalOpen = () => {
    setIsDiscoverDevicesModalOpen(true);
  };

  const handleDRSDiscoverDevicesModalClose = () => {
    setIsDRSDiscoverDevicesModalOpen(false);
  };

  const handleDRSDiscoverDevicesModalOpen = () => {
    if (!wasDRSShown.current) {
      wasDRSShown.current = true;
      setIsDRSDiscoverDevicesModalOpen(true);
    }
  };

  const alexaPermissionModalProps: AlexaPermissionModalProps = {
    modalTitleCopy: permissionsInfoData?.permissionsModalHeader ?? '',
    permissionsModalBodyHeaderText: permissionsInfoData?.permissionsModalBodyHeaderText ?? '',
    permissions: permissionsInfoData?.permissionsInfoList ?? [],
    permissionsModalSaveButtonText: permissionsInfoData?.permissionsModalSaveButtonText ?? '',
    setIsSavingPermissions,
    modalProps: {
      isOpen: isPermissionModalOpen,
      close: handlePermissionModalClose
    }
  };

  const alexaDisableSkillModalProps: AlexaDisableSkillModalProps = {
    modalTitleCopy: disableModalData.disableModalTitle,
    disableSkillBodyCopy: disableModalData.disableModalBody,
    disableButtonCopy: disableModalData.disableModalConfirmText,
    cancelButtonCopy: disableModalData.disableModalCancelText,
    deviceType: deviceType ?? '',
    locale: locale ?? '',
    defaultLocale: defaultLocale ?? '',
    localizedProductDetails,
    setIsDisabling,
    modalProps: {
      isOpen: isDisableModalOpen,
      close: handleDisableModalClose
    }
  };

  const alexaKSTModalProps: AlexaKSTModalProps = {
    kstHeaderTitle: actionBoxData?.parentalConsentMessageData?.kstModalData?.kstHeaderTitle ?? '',
    okButtonText: actionBoxData?.parentalConsentMessageData?.kstModalData?.okButtonText ?? '',
    closeButtonText: actionBoxData?.parentalConsentMessageData?.kstModalData?.closeButtonText ?? '',
    modalProps: {
      isOpen: isKSTModalOpen,
      close: handleKSTModalClose
    },
    kstModalBody: {
      kstName: actionBoxData.parentalConsentMessageData?.kstModalData?.kstModalBodyProps.kstName ?? '',
      kstDescription: actionBoxData.parentalConsentMessageData?.kstModalData?.kstModalBodyProps.kstDescription ?? '',
      kstLearnMoreText: actionBoxData.parentalConsentMessageData?.learnMoreText ?? '',
      kstLearnMoreURL: actionBoxData.parentalConsentMessageData?.learnMoreLink ?? '',
      kstEnabled: actionBoxData.parentalConsentMessageData?.kstStatus ?? false,
      ...actionBoxData?.parentalConsentMessageData?.kstModalData?.kstModalBodyProps
    }
  };

  const drsSkillModalProps: AlexaModalProps = {
    modalTitleCopy: discoverDeviceModalData?.discoverDeviceModalTitle ?? '',
    modalBodyContent: <AlexaText copy={discoverDeviceModalData?.discoverDeviceModalBody} />,
    acceptButtonCopy: discoverDeviceModalData?.discoverDeviceModalDiscoverDevices,
    modalProps: {
      isOpen: isDRSDiscoverDevicesModalOpen,
      close: handleDRSDiscoverDevicesModalClose
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAcceptAction: () => {}
  };

  const discoverDevicesModalProps: AlexaModalProps = {
    modalTitleCopy: discoverDeviceModalData?.discoverDeviceModalTitle ?? '',
    modalBodyContent: <AlexaText copy={discoverDeviceModalData?.discoverDeviceModalBody} />,
    acceptButtonCopy: discoverDeviceModalData?.discoverDeviceModalDiscoverDevices,
    closeButtonCopy: discoverDeviceModalData?.discoverDeviceModalCancel,
    modalProps: {
      isOpen: isDiscoverDevicesModalOpen,
      close: handleDiscoverDevicesModalClose
    },
    onAcceptAction: () => {
      window.open(getAccountLinkUri());
    }
  };

  const getAccountLinkUri = () => {
    if (actionBoxData.accountLinkURI === undefined || actionBoxData.accountLinkURI === '') {
      console.log('accountLinkUri from buyBoxMetaState = ', buyBoxMetaState.accountLinkUri);
      return buyBoxMetaState.accountLinkUri ?? '';
    }
    console.log('accountLinkUri from actionBoxData = ', buyBoxMetaState.accountLinkUri);
    return actionBoxData.accountLinkURI;
  };

  // Enable and Sign in button use same styles, only difference is in the onClick action
  const getEnableOrSignInButton = () => {
    return !isEnabling ? (
      <>
        <AlexaKSTModal {...alexaKSTModalProps} data-cy={'free-skill-buy-box-actionBox-alexaKSTModal'} />
        <AlexaButton
          fontSmoothing
          aria-label={actionBoxData?.primaryButtonData.buttonText ?? ''}
          copy={actionBoxData?.primaryButtonData.buttonText ?? ''}
          color="#111"
          fontFamily="verdana,arial,helvetica,sans-serif"
          background="rgb(255, 216, 20)"
          backgroundHover="#F7CA00"
          borderColor="#FCD200"
          borderColorFocus="#e77600"
          borderRadius="8px"
          boxShadowFocus="0 0 3px 2px rgb(228 121 17 / 50%)"
          border="1px solid"
          fontSize={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? '15px' : '13px'}
          data-cy={'free-skill-buy-box-actionBox-enableOrSignInButton'}
          onClick={async () => {
            if (isAuthenticatedUser) {
              if (
                !actionBoxData?.parentalConsentMessageData?.kstStatus &&
                actionBoxData?.parentalConsentMessageData?.kstRequired
              ) {
                handleKSTModalOpen();
              } else if (
                !actionBoxData?.parentalConsentMessageData?.vpcStatus &&
                actionBoxData?.parentalConsentMessageData?.vpcRequired
              ) {
                window.sessionStorage.removeItem('Initial_invoke');
                getVPCWidgetData({
                  requestId: configRegistryState.requestId,
                  asin: configRegistryState.asin,
                  tenant: configRegistryState.tenant ?? '',
                  marketplaceId: shoppingPortalRegistryState.marketplaceId,
                  sessionId: shoppingPortalRegistryState.sessionId,
                  ubid: shoppingPortalRegistryState.ubid,
                  csrfToken: configRegistryState.csrfToken,
                  customerId: shoppingPortalRegistryState.customerId
                });
              } else if (actionBoxData?.isSmartHomeSkill === true) {
                setIsEnabling(true);
                enableOnAccountLinkWindowClose(getAccountLinkUri());
              } else {
                setIsEnabling(true);
                const response = await enableSkill({
                  requestId: configRegistryState.requestId,
                  asin: configRegistryState.asin,
                  tenant: configRegistryState.tenant ?? '',
                  marketplaceId: shoppingPortalRegistryState.marketplaceId,
                  sessionId: shoppingPortalRegistryState.sessionId,
                  ubid: shoppingPortalRegistryState.ubid,
                  csrfToken: configRegistryState.csrfToken,
                  customerId: shoppingPortalRegistryState.customerId,
                  deviceType,
                  locale,
                  defaultLocale,
                  localizedProductDetails
                });
                if (response) {
                  if (actionBoxData?.additionalAccountLinkData?.doAccountLinkOnEnable) {
                   // const accountLinkURI = await getAccountLinkingURI({
                   //    requestId: configRegistryState.requestId,
                   //    asin: configRegistryState.asin,
                   //    tenant: configRegistryState.tenant ?? '',
                   //    marketplaceId: shoppingPortalRegistryState.marketplaceId,
                   //    sessionId: shoppingPortalRegistryState.sessionId,
                   //    ubid: shoppingPortalRegistryState.ubid,
                   //    csrfToken: configRegistryState.csrfToken,
                   //    customerId: shoppingPortalRegistryState.customerId,
                   //    localizedProductDetails,
                   //  });
                    reloadOnAccountLinkWindowClose(getAccountLinkUri(), true);
                  } else {
                    buyBoxActions.update(response as BuyBoxState);
                    if ((response as BuyBoxState).permissionsInfoData) {
                      window.dispatchEvent(new Event('showPermissionModal'));
                      handlePermissionModalOpen();
                    }
                  }
                }
                setIsEnabling(false);
              }
            } else {
              redirectToAuthPortalV2(configRegistryState.countryCode);
            }
          }}
        />
      </>
    ) : (
      // TODO: pass Enabling Skill... text from server
      <AlexaButton
        fontSmoothing
        aria-label={actionBoxData?.buttonEnablingText ?? ''}
        copy={actionBoxData?.buttonEnablingText ?? ''}
        color="#111"
        fontSize={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? '15px' : '13px'}
        background="#f8f3e6 !important"
        borderColor="#e7e0cf !important"
        borderRadius="3px"
        border="1px solid"
        disabled={true}
        data-cy={'free-skill-buy-box-actionBox-enablingStatePrimaryButton'}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={() => {}}
      />
    );
  };

  const getDisableButton = () => {
    return !isDisabling ? (
      <>
        <AlexaDisableSkillModal
          {...alexaDisableSkillModalProps}
          data-cy={'free-skill-buy-box-actionBox-disableSkillModal'}
        />
        <AlexaButton
          fontSmoothing
          aria-label={actionBoxData.primaryButtonData.buttonText}
          copy={actionBoxData.primaryButtonData.buttonText}
          color="#fff"
          background="linear-gradient(to bottom, #72787F, #444C55)"
          backgroundHover="linear-gradient(to bottom, #666b72, #393f47)"
          borderColor="#3d444c #2f353b #2c3137"
          borderColorFocus="#32373e #24282d #212429"
          borderColorHover="#32373e #24282d #212429"
          borderRadius="3px"
          boxShadowFocus="0 0 3px 2px rgb(228 121 17 / 50%)"
          border="1px solid"
          fontWeight="700"
          fontSize={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? '15px' : '13px'}
          data-cy={'free-skill-buy-box-actionBox-disableSkillButton'}
          onClick={() => {
            handleDisableModalOpen();
          }}
        />
      </>
    ) : (
      // TODO: pass Disabling Skill... text from server
      <AlexaButton
        fontSmoothing
        aria-label={actionBoxData?.buttonDisablingText ?? ''}
        copy={actionBoxData?.buttonDisablingText ?? ''}
        color="#111"
        background="#dddddd !important"
        borderColor="#bababa !important"
        borderRadius="3px"
        border="1px solid"
        fontWeight="700"
        disabled={true}
        data-cy={'free-skill-buy-box-actionBox-disablingStatePrimaryButton'}
        fontSize={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? '15px' : '13px'}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={() => {}}
      />
    );
  };

  const renderPrimaryButton = () => {
    return !isSkillEnabled || !isAuthenticatedUser ? getEnableOrSignInButton() : getDisableButton();
  };

  const renderSecondaryButton = () => {
    return (
      <>
        {isSkillEnabled && drsManageDeviceText !== undefined && (
          <DrsMessageDiv data-cy={'free-skill-buy-box-actionBox-drsMessage'}>
            <InfoIcon />
            <AlexaText
              textAlign="left"
              padding="0 0 0 28px"
              fontSize={alexaTheme.typography?.mobileBody?.fontSize}
              data-cy={'free-skill-buy-box-actionBox-drsMessageText'}
            >
              {drsManageDeviceText}
            </AlexaText>
          </DrsMessageDiv>
        )}
        {isSkillEnabled && actionBoxData.secondaryButtonData && drsManageDeviceText === undefined && (
          <AlexaButton
            fontSmoothing
            aria-label={actionBoxData.secondaryButtonData.buttonText}
            background="linear-gradient(to bottom, #f7f8fa, #e7e9ec)"
            backgroundHover="linear-gradient(to bottom, #e7eaf0, #d9dce1)"
            borderColor="#ADB1B8 #A2A6AC #8D9096"
            borderColorHover="#a2a6ac #979aa1 #82858a"
            borderColorFocus="#e77600"
            borderRadius="3px"
            border="1px solid"
            fontSize={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? '15px' : '13px'}
            copy={actionBoxData.secondaryButtonData.buttonText}
            margin="8px 0px 0px"
            boxShadowFocus="0 0 3px 2px rgb(228 121 17 / 50%)"
            data-cy={'free-skill-buy-box-actionBox-secondaryButton'}
            onClick={async () => {
              // const accountLinkingURI =
              //   actionBoxData.accountLinkURI ??
              //   (await getAccountLinkingURI({
              //     requestId: configRegistryState.requestId,
              //     asin: configRegistryState.asin,
              //     tenant: configRegistryState.tenant ?? '',
              //     marketplaceId: shoppingPortalRegistryState.marketplaceId,
              //     sessionId: shoppingPortalRegistryState.sessionId,
              //     ubid: shoppingPortalRegistryState.ubid,
              //     csrfToken: configRegistryState.csrfToken,
              //     customerId: shoppingPortalRegistryState.customerId,
              //     localizedProductDetails
              //   }));
              reloadOnAccountLinkWindowClose(getAccountLinkUri());
            }}
          />
        )}
      </>
    );
  };

  const renderFlashBriefingButton = () => {
    return (
      <>
        <AlexaButton
          fontSmoothing
          aria-label={actionBoxData.contentSkillData?.manageContentText}
          background="linear-gradient(to bottom, #f7f8fa, #e7e9ec)"
          backgroundHover="linear-gradient(to bottom, #e7eaf0, #d9dce1)"
          borderColor="#ADB1B8 #A2A6AC #8D9096"
          borderColorHover="#a2a6ac #979aa1 #82858a"
          borderColorFocus="#e77600"
          borderRadius="3px"
          border="1px solid"
          fontSize={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? '15px' : '13px'}
          copy={actionBoxData.contentSkillData?.manageContentText}
          margin="8px 0px 0px"
          boxShadowFocus="0 0 3px 2px rgb(228 121 17 / 50%)"
          data-cy={'free-skill-buy-box-actionBox-flashBriefingButton'}
          onClick={() => {
            window.open(actionBoxData.contentSkillData?.manageContentURI, '_blank');
          }}
        />
      </>
    );
  };

  const renderMessages = () => {
    return (
      <>
        {isAuthenticatedUser && actionBoxData.additionalAccountLinkData?.accountLinkingText && (
          <AlexaText
            fontSmoothing
            copy={actionBoxData.additionalAccountLinkData?.accountLinkingText}
            fontSize="13px"
            color="#565959"
            margin="8px 0px 0px 0px"
            data-cy={'free-skill-buy-box-actionBox-messages'}
          />
        )}
      </>
    );
  };

  const renderRestrictionMessage = () => {
    return restrictionMessage === undefined || restrictionOverride ? null : (
      <CanvasItem
        width="100%"
        padding="14px 18px"
        textAlign="center"
        data-cy={'free-skill-buy-box-restriction-message-container'}
      >
        {renderSkillSpecificMessage()}
        {isAuthenticatedUser ? (
          <RestrictionMessage
            data-cy={'free-skill-buy-box-restriction-message-item'}
            restrictionMessage={restrictionMessage ?? ''}
            isMShop={deviceType === 'MSHOP'}
          />
        ) : (
          renderPrimaryButton()
        )}
      </CanvasItem>
    );
  };

  const renderHeader = () => {
    return restrictionMessage !== undefined && !restrictionOverride ? null : (
      <CanvasItem
        width="100%"
        borderBottom="1px solid #ddd"
        padding="14px 18px"
        data-cy={'free-skill-buy-box-header-item'}
      >
        <AlexaText
          bold
          copy={headingCopy}
          lineHeight="20px"
          fontSize="14px"
          data-cy={'free-skill-buy-box-header-text'}
        />
      </CanvasItem>
    );
  };

  const renderSkillSpecificMessage = () => {
    return actionBoxData.skillSpecificMessage === undefined ? null : (
      <SkillSpecificMessage
        data-cy={'free-skill-buy-box-actionBox-skillSpecificMessage'}
        message={actionBoxData.skillSpecificMessage}
      />
    );
  };

  const renderParentalConsentMessage = () => {
    return actionBoxData.parentalConsentMessageData === undefined ? null : (
      <SkillSpecificMessage
        data-cy={'free-skill-buy-box-actionBox-parentalConsentMessage'}
        message={actionBoxData.parentalConsentMessageData?.parentalConsentMessage}
        learnMoreLink={actionBoxData.parentalConsentMessageData?.learnMoreLink}
        learnMoreText={actionBoxData.parentalConsentMessageData?.learnMoreText}
      />
    );
  };
  const renderActionBox = () => {
    return restrictionMessage !== undefined && !restrictionOverride ? null : (
      <CanvasItem
        width="100%"
        borderBottom={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? 'none' : '1px solid #ddd'}
        padding={deviceType === 'MOBILE' || deviceType === 'MSHOP' ? '14px 0px' : '14px 18px'}
        textAlign="center"
        data-cy={'free-skill-buy-box-actionBox'}
      >
        {getActionBoxContents()}
      </CanvasItem>
    );
  };

  const getActionBoxContents = () => {
    if (mshopDownloadAlexaAppData === undefined) {
      return (
        <>
          <AlexaModal data-cy={'free-skill-buy-box-actionBox-discoverDevicesModal'} {...discoverDevicesModalProps} />
          <AlexaModal data-cy={'free-skill-buy-box-actionBox-drsSkillModal'} {...drsSkillModalProps} />
          {renderSkillSpecificMessage()}
          {renderParentalConsentMessage()}
          {renderPrimaryButton()}
          {renderSecondaryButton()}
          {isSkillEnabled &&
            isAuthenticatedUser &&
            actionBoxData.contentSkillData?.isContentSkill &&
            renderFlashBriefingButton()}
          {renderMessages()}
        </>
      );
    } else {
      return (
        <MShopDownloadAlexaApp
          data-cy={'free-skill-buy-box-actionBox-mShopDownloadAlexaApp'}
          {...mshopDownloadAlexaAppData}
        ></MShopDownloadAlexaApp>
      );
    }
  };

  const renderPermissionsBox = () => {
    return permissionsInfoData === undefined ? null : (
      <CanvasItem
        width="100%"
        borderBottom="1px solid #ddd"
        padding="14px 18px"
        fontSize="0.85em"
        textAlign="left"
        data-cy={'free-skill-buy-box-permissionsBox'}
      >
        <AlexaText
          bold
          fontSmoothing
          copy={permissionsInfoData.buyBoxPermissionsHeader}
          lineHeight="19px"
          fontSize="13px"
          data-cy={'free-skill-buy-box-permissionsBox-permissionsHeader'}
        />
        {/* todo: add fontSmoothing to list items */}
        <List
          items={permissionsInfoData.permissionsInfoList?.map((a) => a.name)}
          margin="0"
          padding="0 0 0px 18px"
          lineHeight="19px"
          data-cy={'free-skill-buy-box-permissionsBox-permissionsInfoList'}
        />
        {isAuthenticatedUser &&
          isSkillEnabled &&
          (!isSavingPermissions ? (
            <>
              <AlexaPermissionModal
                {...alexaPermissionModalProps}
                data-cy={'free-skill-buy-box-permissionsBox-permissionModal'}
              />
              <AlexaButton
                fontSmoothing
                aria-label={permissionsInfoData.permissionButtonData?.buttonText ?? ''}
                background="linear-gradient(to bottom,#f7f8fa,#e7e9ec)"
                backgroundHover="linear-gradient(to bottom,#e7eaf0,#d9dce1)"
                borderColor="#ADB1B8 #A2A6AC #8D9096"
                borderColorHover="#a2a6ac #979aa1 #82858a"
                borderColorFocus="#e77600"
                borderRadius="3px"
                border="1px solid"
                copy={permissionsInfoData.permissionButtonData?.buttonText ?? ''}
                margin="8px 0px 0px"
                boxShadowFocus="0 0 3px 2px rgb(228 121 17 / 50%)"
                data-cy={'free-skill-buy-box-permissionsBox-permissionButton'}
                onClick={() => {
                  handlePermissionModalOpen();
                }}
              />
            </>
          ) : (
            <AlexaButton
              fontSmoothing
              aria-label={permissionsInfoData?.permissionsSavingSettings ?? ''}
              copy={permissionsInfoData?.permissionsSavingSettings ?? ''}
              color="#111"
              background="#dddddd !important"
              borderColor="#bababa !important"
              borderRadius="3px"
              border="1px solid"
              margin="8px 0px 0px"
              disabled={true}
              data-cy={'free-skill-buy-box-permissionsBox-permissionsSavingSettings'}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() => {}}
            />
          ))}
      </CanvasItem>
    );
  };

  const renderFooter = () => {
    return restrictionMessage !== undefined && !restrictionOverride ? null : (
      <CanvasItem padding="14px 18px" textAlign="left" data-cy={'free-skill-buy-box-footer'}>
        <AlexaText
          fontSmoothing
          copy={footerCopy}
          fontSize="14px"
          lineHeight="20px"
          color="#565959"
          data-cy={'free-skill-buy-box-footerText'}
        />
      </CanvasItem>
    );
  };
  
  /**
   * Method to emit metrics after Accountlinking window is closed
   * After rehydration, if skill is enabled and secondary button
   * to link account (ButtonActionTypes.LinkAccount) is not present 
   * then we *think* that account linking flow was successful
   * 
   * Existence of `Link Account` button indicates that account linking was not successful.
   * 
   * Note that this is not a perfect metric as details of account linking flow are not visible to Monera.
   * We just make an educated guess to emit these metrics.
   * 
   * @param response as BuyBoxState returned by rehydrate call
   */
  const emitAccountLinkingMetrics = (response: BuyBoxState) => {
    async function actuallyEmitMetrics(response:BuyBoxState) {
      const secondaryButtonAction = response.actionBoxData?.secondaryButtonData?.buttonAction;
      if (response.isSkillEnabled
        && (secondaryButtonAction? secondaryButtonAction !== ButtonActionTypes.LinkAccount: true)) {
        await emitCountMetrics(
          AccountLinkingMetric.methodName,
          AccountLinkingMetric.successMetricName,
          1,
          configRegistryState.requestId,
          configRegistryState.tenant
        ); 
      }else{
        await emitCountMetrics(
          AccountLinkingMetric.methodName,
          AccountLinkingMetric.errorMetricName,
          1,
          configRegistryState.requestId,
          configRegistryState.tenant
        );
      }
    }
    actuallyEmitMetrics(response)
    .then(() => serverConsole.log(`Successfully emitted ${AccountLinkingMetric.methodName} metrics`))
    .catch((err: any) => serverConsole.error(`Error while emitting ${AccountLinkingMetric.methodName} metrics: ${err}`));
  };

  return (
    <>
      <CanvasContainer
        alignItems="center"
        border={deviceType !== 'MOBILE' && deviceType !== 'MSHOP' ? coreTheme.borders?.buyBox?.border : 'none'}
        borderRadius={
          restrictionMessage && !restrictionOverride ? '4px 4px 0 0' : coreTheme.borders?.buyBox?.borderRadius
        }
        direction="column"
        justify="center"
        width={deviceType === 'MSHOP' || deviceType === 'MOBILE' ? '100%' : '300px'}
        data-cy={'free-skill-buy-box'}
      >
        {/* Restriction Message */}
        {renderRestrictionMessage()}

        {/* Header */}
        {deviceType !== 'MOBILE' && deviceType !== 'MSHOP' && renderHeader()}

        {/* Action Box */}
        {renderActionBox()}

        {/* Permissions Box */}
        {deviceType !== 'MOBILE' && deviceType !== 'MSHOP' && renderPermissionsBox()}

        {/* Footer */}
        {deviceType !== 'MOBILE' && deviceType !== 'MSHOP' && renderFooter()}
      </CanvasContainer>
    </>
  );
};

export const BuyBoxWithAnalytics = withViewAnalytics(FreeSkillBuyBox)({
  analyticsLabel: 'BUY_NOW_BUY_BOX'
});

export * from './types';
